import React from 'react';
import clsx from 'clsx';

import styled from '@emotion/styled';
import { Icon } from 'components';

interface MobileTabsProps {
  value: string;
  onTabClick: (
    value: string,
    updateType?: 'replace' | 'push' | 'replaceIn' | 'pushIn' | undefined
  ) => void;
}

const MobileTabs: React.FC<MobileTabsProps> = ({ value, onTabClick }) => {
  return (
    <StyledMobileTabs className="mobile-tabs">
      <button
        onClick={() => onTabClick('list', 'replaceIn')}
        className={clsx({ active: value === 'list' })}>
        <Icon name="list" />
        <span className="mobile-tabs__text">List</span>
      </button>
      <button
        onClick={() => onTabClick('map', 'replaceIn')}
        className={clsx({ active: value === 'map' })}>
        <Icon name="pin" />
        <span className="mobile-tabs__text">Map</span>
      </button>
    </StyledMobileTabs>
  );
};

export default MobileTabs;

const StyledMobileTabs = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.colors.white};
  z-index: 1000;

  button {
    width: 50%;
    border: none;
    background: none;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    height: 100%;
    padding: 0;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      color: ${props => props.theme.colors.primary};
      .icon {
        fill: ${props => props.theme.colors.primary};
      }
    }

    .icon {
      vertical-align: middle;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      fill: ${props => props.theme.colors.harborGray};
    }
  }
  .mobile-tabs {
    &__text {
      display: bl;
    }
  }
`;
