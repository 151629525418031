import React, { useRef, useState, useCallback, useEffect, useMemo, useContext } from 'react';
import { Link } from 'gatsby';
import {
  useQueryParam,
  StringParam,
  BooleanParam,
  withDefault,
  JsonParam,
  useQueryParams,
  NumberParam,
  ArrayParam
} from 'use-query-params';
import { useLocation } from '@reach/router';
import { useDebouncedCallback } from 'use-debounce';
import clsx from 'clsx';

import { AuthContext, AuthModalContext } from 'auth';
import { Components, info } from 'sitedata';
import notify from 'notify';
import { useLayout } from 'hooks';
import { getFirstError } from 'utils';
import { getListingsSearch, saveSearch as saveSearchRequest } from 'api/listings';
import { AuthMenu, Button, Icon } from 'components';
import SearchAutocomplete, { AutocompleteSubmitValue } from 'components/SearchAutocomplete';
import { AsideMenu } from 'components/Header';
import { CounterAndSort } from './sections';
import FiltersForm from './FiltersForm';
import Results from './sections/Results';
import ListingsMap from './sections/ListingsMap';
import { MapData } from './types';
import { getNonEmptyKeys, formatFiltersQuery } from './searchPageHelpers';
import MobileTabs from './sections/MobileTabs';
import StyledSearchPageLayout from './StyledSearchPageLayout';

const defaultSearch = info.defaultSearch || 'Chicago';

const SearchPage = () => {
  const { authorized } = useContext(AuthContext);
  const { setAuthModalOpen } = useContext(AuthModalContext);
  const layout = useLayout();
  const location = useLocation();
  const cardsContainerRef = useRef<any>();
  const abortController = useRef<AbortController>();
  const [listings, setListings] = useState<ListingCardData[]>([]);
  const [listingsMarkers, setListingsMarkers] = useState<ListingsSearchMarker[]>([]);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openMenuModal, setOpenMenuModal] = useState(false);
  const [, setAuthType] = useQueryParam('authType', StringParam);
  const [, setMapBoundsFitted] = useQueryParam('mapBoundsFitted', BooleanParam);
  const [view, setView] = useQueryParam('view', withDefault(StringParam, 'list'));
  const [sort, setSort] = useQueryParam('sort', withDefault(StringParam, 'recommended'));
  const [mapData, setMapData] = useQueryParam<MapData>('mapData', JsonParam);
  const [paginationData, setPaginationData] = useQueryParams({
    page: NumberParam,
    total_pages: NumberParam,
    total_results: NumberParam
  });
  const offset = paginationData.page ? paginationData.page - 1 : 0;
  const cityInit = new URLSearchParams(location.search).get('city');
  const [filters, _setFilters] = useQueryParams({
    // formatted in 'formatFiltersQuery'
    bounds: JsonParam,
    bathrooms: NumberParam,
    parking_spaces: NumberParam,
    with_images: BooleanParam,
    // used as is in request
    search_string: withDefault(
      StringParam,
      cityInit ? cityInit[0].toUpperCase() + cityInit.slice(1) : defaultSearch
    ),
    beds_max: NumberParam,
    beds_min: NumberParam,
    listing_status: ArrayParam,
    listing_type_hr: ArrayParam,
    other_amenities: JsonParam,
    places: StringParam,
    price_max: NumberParam,
    price_min: NumberParam,
    sqft_max: NumberParam,
    sqft_min: NumberParam,
    year_built_max: NumberParam,
    year_built_min: NumberParam,
    // address filters
    area: StringParam,
    city: StringParam,
    county: StringParam,
    elementary_school: StringParam,
    high_school: StringParam,
    middle_or_junior_school: StringParam,
    postal_code: StringParam,
    state: StringParam,
    street: StringParam
  });
  const setFilters = (useDebouncedCallback(_setFilters, 500)[0] as unknown) as typeof _setFilters;
  const prevFilters = useRef<string | undefined>();
  const allFilters = useMemo(() => {
    const resetPage = prevFilters.current !== JSON.stringify(filters);
    return {
      ...filters,
      offset: resetPage ? 0 : offset,
      sort
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), offset, sort]);

  const getListings = useCallback(
    async filters => {
      // eslint-disable-next-line no-unused-expressions
      if (loading) abortController.current?.abort();
      setLoading(true);

      try {
        const controller = new AbortController();
        abortController.current = controller;

        const searchParams = formatFiltersQuery(filters);
        const paramsKeys = Object.keys(searchParams);
        if (paramsKeys.filter(item => !['offset', 'sorty_by'].includes(item)).length === 0) {
          searchParams.search_string = defaultSearch;
        }
        if (searchParams.search_string === 'null') {
          searchParams.search_string = undefined;
        }
        const { listings, markers, pagination } = await getListingsSearch(searchParams, {
          signal: controller.signal
        });
        if (listings.length === 0) notify('No results');
        setListings(listings);
        setMapBoundsFitted(false, 'replaceIn');
        if (cardsContainerRef.current) cardsContainerRef.current.scrollTop = 0;
        if (markers) setListingsMarkers(markers);
        setPaginationData(pagination, 'replaceIn');
      } catch (err) {
        if (err.name !== 'AbortError') {
          setListings([]);
          setListingsMarkers([]);
          setPaginationData({ page: 1, total_results: 0, total_pages: 0 }, 'replaceIn');
          notify(err.message);
        }
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [abortController, loading]
  );

  const saveSearch = async () => {
    if (!authorized) {
      setAuthModalOpen(true);
      return;
    }

    try {
      const filterWithMapData = getNonEmptyKeys({ ...allFilters, mapData });
      const url = new URL(location.href);
      Object.entries(filterWithMapData).forEach(([key, value]) => {
        if (!url.searchParams.has(key)) url.searchParams.append(key, value);
      });
      // if (Object.keys(filters).length === 0) throw Error('No search query or filters to save');
      await saveSearchRequest(url.toString());
      notify('Search saved');
    } catch (err) {
      notify(getFirstError(err.response) || err.message);
    }
  };

  const handleProtectedLinkClick = e => {
    if (!authorized) {
      e.preventDefault();
      setAuthModalOpen(true);
      if (e.target.dataset.authtype) setAuthType(e.target.dataset.authtype);
    }
  };

  const handleAutocompleteSubmit = ({ suggestion, query }: AutocompleteSubmitValue) => {
    setFilters(
      {
        search_string: query,
        bounds: undefined, // clear bounds filter
        area: suggestion?.area,
        city: suggestion?.city,
        county: suggestion?.county,
        elementary_school: suggestion?.elementary_school,
        middle_or_junior_school: suggestion?.middle_or_junior_school,
        high_school: suggestion?.high_school,
        postal_code: suggestion?.postal_code,
        street: suggestion?.street,
        state: suggestion?.state
      },
      'replaceIn'
    );
  };

  const handleSearchThisAreaClick = () => {
    setFilters(
      {
        search_string: '',
        bounds: mapData?.bounds,
        area: undefined,
        city: undefined,
        county: undefined,
        elementary_school: undefined,
        middle_or_junior_school: undefined,
        high_school: undefined,
        postal_code: undefined,
        street: undefined,
        state: undefined
      },
      'replaceIn'
    );
  };

  useEffect(() => {
    prevFilters.current = JSON.stringify(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    getListings(allFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(allFilters)]);

  const sections = {
    filtersForm: (
      <FiltersForm
        initialValues={filters}
        onCloseRequest={() => setShowMoreFilters(false)}
        onSubmit={filters => setFilters(filters, 'replaceIn')}
        onSaveSearchClick={saveSearch}
        showMoreFilters={showMoreFilters}
        onExpandButtonClick={() => setShowMoreFilters(v => !v)}
      />
    ),
    counterAndSort: (
      <CounterAndSort sort={sort} onSortChange={setSort} paginationData={paginationData} />
    ),
    results: (
      <Results
        ref={cardsContainerRef}
        items={listings}
        loading={loading}
        page={Number(paginationData.page)}
        totalPages={Number(paginationData.total_pages)}
        totalResults={Number(paginationData.total_results)}
        offset={offset}
        onPaginationChange={setPaginationData}
      />
    ),
    map: (
      <ListingsMap
        mapData={mapData}
        items={listingsMarkers}
        onMapChange={setMapData}
        onSearchThisAreaClick={handleSearchThisAreaClick}
      />
    ),
    searchInput: (
      <div className="search-wrapper">
        <SearchAutocomplete
          before={<Icon name="pin-simple" />}
          value={filters.search_string}
          placeholder={
            layout === 'desktop'
              ? 'Search: City, Neighborhood, Address, School, ZIP,  MLS #'
              : 'City, School, ZIP...'
          }
          onSubmit={handleAutocompleteSubmit}
          data-cy="search_input"
        />
      </div>
    ),
    signInButton: authorized ? (
      <AuthMenu className="auth" />
    ) : (
      <Button link onClick={() => setAuthModalOpen(true)} className="auth" data-cy="sign_in_button">
        Sign in
      </Button>
    ),
    mobileTabs: <MobileTabs value={view} onTabClick={setView} />
  };

  const {
    filtersForm,
    counterAndSort,
    results,
    map,
    searchInput,
    signInButton,
    mobileTabs
  } = sections;

  const getLayout = () => {
    switch (layout) {
      case 'mobile':
        return (
          <>
            <header className="header">
              <Components.Logo to="/" data-cy="logo" />
              {searchInput}
              <Button
                simple
                className="menu-button"
                data-cy="menu_button"
                onClick={() => setOpenMenuModal(true)}>
                <Icon name="burger-menu" data-cy="burger_icon" />
              </Button>
              <AsideMenu
                data-cy="aside_menu"
                open={openMenuModal}
                onClose={() => setOpenMenuModal(false)}
              />
            </header>
            <div className="content" data-cy="content_box">
              {filtersForm}
              {view === 'list' && counterAndSort}
              {view === 'list' && results}
              {view === 'map' && map}
            </div>
            {mobileTabs}
          </>
        );

      case 'tablet':
        return (
          <>
            <header className="header">
              <Components.Logo to="/" data-cy="logo" />
              <Link
                className="header__link"
                data-cy="saved_listings_link"
                to="/saved-listings"
                data-authtype="savedListings"
                onClick={handleProtectedLinkClick}>
                <Icon name="heart" /> Saved Listings
              </Link>
              <Link
                className="header__link"
                data-cy="saved_searches_link"
                to="/saved-searches"
                onClick={handleProtectedLinkClick}>
                <Icon name="search" /> Saved Searches
              </Link>
              {signInButton}
            </header>
            <div className="content" data-cy="content_box">
              {searchInput}
              {filtersForm}
              {view === 'list' && !showMoreFilters && counterAndSort}
              {view === 'list' && !showMoreFilters && results}
              {view === 'map' && !showMoreFilters && map}
            </div>

            {!showMoreFilters && mobileTabs}
          </>
        );

      default:
        return (
          <>
            {map}
            <header className="header">
              <Components.Logo to="/" data-cy="logo" />
              <Link
                className="header__link"
                data-cy="saved_listings_link"
                to="/saved-listings"
                data-authtype="savedListings"
                onClick={handleProtectedLinkClick}>
                <Icon name="heart" /> Saved Listings
              </Link>
              <Link
                className="header__link"
                data-cy="saved_searches_link"
                to="/saved-searches"
                onClick={handleProtectedLinkClick}>
                <Icon name="search" /> Saved Searches
              </Link>
              {signInButton}
            </header>
            <div className="content" data-cy="content_box">
              {searchInput}
              {filtersForm}
              {!showMoreFilters && counterAndSort}
              {!showMoreFilters && results}
            </div>
          </>
        );
    }
  };

  return (
    <StyledSearchPageLayout className={clsx('search-layout', view)}>
      {getLayout()}
    </StyledSearchPageLayout>
  );
};

export default SearchPage;
