import React from 'react';
import styled from '@emotion/styled';

import ListingCard, { ListingCardSkeleton } from 'components/ListingCard';
import Pagination from 'components/Pagination';
import LazyLoad from 'react-lazyload';

const PAGE_SIZE = 20;

const clamp = (current: number, max: number) => (current > max ? max : current);

interface ResultsProps {
  ref: any;
  loading: boolean;
  items: ListingCardData[];
  page: number;
  totalPages: number;
  totalResults: number;
  offset: number;
  onPaginationChange: (
    newValue: { page: number },
    updateType?: 'replaceIn' | 'replace' | 'push' | 'pushIn' | undefined
  ) => void;
}

const Results: React.FC<ResultsProps> = React.forwardRef(
  ({ loading, items, page, totalPages, totalResults, offset, onPaginationChange }, ref: any) => {
    const isEmpty = items.length === 0;

    return (
      <StyledResults ref={ref} className="results" id="lazy-scroll-container">
        <div className="results__cards">
          {loading
            ? Array(6)
                .fill(undefined)
                .map((_, idx) => (
                  <ListingCardSkeleton
                    // eslint-disable-next-line react/no-array-index-key
                    key={idx}
                    style={{ width: '100%' }}
                  />
                ))
            : items.map(item => (
                <LazyLoad
                  key={item.id}
                  height={276}
                  scrollContainer={document.querySelector('#lazy-scroll-container') || undefined}
                  overflow>
                  <ListingCard data={item} />
                </LazyLoad>
              ))}
        </div>
        {isEmpty && !loading && (
          <div className="results__no-results">
            There are no listings that match your request.
            <br />
            Please search by another keywords.
          </div>
        )}
        <div className="pagination-container">
          {totalPages > 1 && (
            <>
              <div className="pagination-info">
                Displaying {offset * PAGE_SIZE + 1}-
                {clamp((offset + 1) * PAGE_SIZE, totalResults || 0)} from {totalResults} results
              </div>
              <Pagination
                pageSize={PAGE_SIZE}
                total={totalResults || undefined}
                current={page || undefined}
                onChange={page => onPaginationChange({ page }, 'replaceIn')}
              />
            </>
          )}
        </div>
      </StyledResults>
    );
  }
);

export default Results;

const StyledResults = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  overflow: auto;
  height: 100%;

  .results__cards {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 16px;
    margin-bottom: 16px;
    width: 100%;

    .property-card {
      margin: 0 auto;
      width: 100%;
    }
  }

  .results__no-results {
    font-size: 16px;
    line-height: 24px;
    max-width: 345px;
    text-align: center;
    margin: auto;
  }
`;
